import React, {memo} from 'react';
import {Position} from 'reactflow';
import TextBox from "../TextBox";
import Select from "../Select";
import BooleanSelection from "../BooleanSelection";
import LogoTitleHandle from "../LogoTitleHandle";
import CollapseMenu from "../CollapseMenu";
import {Title} from "@radix-ui/react-dialog";


function ShopifyNode({id, data}) {
    return (
        <>
            <LogoTitleHandle handlerPosition={Position.Left}
                             handlerType={"target"}
                             title={"Shopify"}
                             logoSrc={"../../logos/shopify.png"}/>
            <CollapseMenu
                content={
                    <div className="custom-node__body">
                        <div className="custom-node__header__text__medium">{data.name}</div>

                        {/*// todo: include when editing configuration is enabled*/}
                        {/*<BooleanSelection/>*/}
                        {/*{Object.keys(data.selects).map((conf) => (*/}
                        {/*    <Select key={conf}*/}
                        {/*            nodeId={id}*/}
                        {/*            initialConf={data.selects[conf].selected}*/}
                        {/*            confId={conf}*/}
                        {/*            options={data.selects[conf].options}*/}
                        {/*            userTextHint={data.selects[conf].hintText}/>*/}
                        {/*))}*/}
                        {/*<TextBox hint={"Marketplace ID"} initialText={data.marketPlaceId}/>*/}
                        {/*<TextBox hint={"API KEY"} initialText={data.apiKey} password={true}/>*/}
                    </div>

                }
            />
        </>
    );
}

export default memo(ShopifyNode);
