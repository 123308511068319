import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import React from "react"
import {useNavigate} from "react-router-dom"
import Button from "../../fundamentals/button"
import Avatar from "../../atoms/avatar";
import SellintuService from "../../../services/sellintu-service";

const UserMenu: React.FC = () => {
    const navigate = useNavigate()

    const logOut = () => {
        new SellintuService().logout()
            .then((response) => {
                navigate("/login")
            })
            .catch((error) => {
                console.log(error)
            });
    }
    return (
        <div className="h-large w-large">
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild disabled={false}>
                    <div className="h-full w-full cursor-pointer">
                        <Avatar
                            isLoading={false}
                            color="bg-grey-80"
                        />
                    </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                    sideOffset={12}
                    side="bottom"
                    className="ml-large rounded-rounded border-grey-20 bg-grey-0 p-xsmall shadow-dropdown z-30 min-w-[200px] border"
                >

                    <DropdownMenu.Item className="outline-none">
                        <Button
                            variant="ghost"
                            size="small"
                            className={"w-full justify-start text-rose-50"}
                            onClick={() => logOut()}
                        >
                            {/*<SignOutIcon size={20} />*/}
                            Sign out
                        </Button>
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </div>
    )
}

export default UserMenu
