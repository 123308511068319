import React, {useEffect, useState} from "react";
import AscDescTable from "../../components/organisms/orders-table";
import Layout from "../../components/templates/layout/layout";
import SellintuService from "../../services/sellintu-service";
import Spinner from "../../components/atoms/spinner";
import {useNavigate} from "react-router-dom";

function OrdersPage() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        new SellintuService().ordersGet()
            .then((response) => {
                // @ts-ignore
                setOrders(response.data)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                if (error.response.status === 401) {
                    navigate("/login")
                }
            });
    }, [])

    return (<>
        <Layout>
            {loading ? (
                <div className="card-body d-flex justify-content-center align-items-center">
                    <Spinner size="super-large" variant="secondary"/>
                </div>
            ) : <AscDescTable orders={orders}/>}
        </Layout>
    </>)
}

export default OrdersPage
