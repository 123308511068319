import React, {useState} from "react"

import SidebarMenuItem from "../../molecules/sidebar-menu-item";
import CartIcon from "../../fundamentals/icons/cartIcon";
import FlowIcon from "../../fundamentals/icons/flow-icon";
import UserMenu from "../../molecules/user-menu";

const ICON_SIZE = 20

const Sidebar: React.FC = () => {
    const [currentlyOpen, setCurrentlyOpen] = useState(-1)

    const triggerHandler = () => {
        const id = triggerHandler.id++
        return {
            open: currentlyOpen === id,
            handleTriggerClick: () => setCurrentlyOpen(id),
        }
    }
    // We store the `id` counter on the function object, as a state creates
    // infinite updates, and we do not want the variable to be free floating.
    triggerHandler.id = 0
    return (
        <div
            className="min-w-sidebar max-w-sidebar bg-gray-0 border-grey-20 py-base px-base h-screen overflow-y-auto border-r">
            <div className="h-full">
                <div className="flex justify-between px-2">
                    <div
                        className="rounded-circle flex h-8 w-8 items-center justify-center border border-solid border-gray-300">
                        <UserMenu/>
                    </div>
                </div>
                <div className="my-base flex flex-col px-2">
                    <span className="text-grey-50 text-small font-medium">Sellintu app</span>
                    <span className="text-grey-90 text-medium font-medium"> {"Backoffice"}</span>
                </div>
                <div className="py-3.5">
                    <SidebarMenuItem
                        pageLink={"/a/orders"}
                        icon={<CartIcon size={ICON_SIZE}/>}
                        triggerHandler={triggerHandler}
                        text={"Orders"}
                    />
                </div>
                <div className="py-3.5">
                    <SidebarMenuItem
                        pageLink={"/a/sellflow"}
                        icon={<FlowIcon size={ICON_SIZE}/>}
                        triggerHandler={triggerHandler}
                        text={"Sellflow"}
                    />
                </div>
            </div>
        </div>
    )
}

export default Sidebar
