import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {
    addEdge,
    Background,
    Connection,
    Controls,
    Edge,
    MarkerType,
    MiniMap,
    Panel,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import MiraklNode from '../../components/organisms/flow/nodes/MiraklNode';
import AmazonNode from "../../components/organisms/flow/nodes/FBANode";
import ShopifyNode from "../../components/organisms/flow/nodes/ShopifyNode";

import 'reactflow/dist/style.css';
import '../../style/customNodes.css';
import '../../style/nodes.css';
import AddNodePanel from "../../components/organisms/flow/AddNodePanel";
import SellintuService from "../../services/sellintu-service";
import Spinner from "../../components/atoms/spinner";
import {useNavigate} from "react-router-dom";

const nodeTypes = {
    mirakl: MiraklNode,
    shopify: ShopifyNode,
    amazon: AmazonNode,
};

const minimapStyle = {
    height: 120,
};

const onInit = (reactFlowInstance: any) => console.log('flow loaded:', reactFlowInstance);


const SellFlowPage = () => {

    const [nodes, setNodes] = useState()
    const [edges, setEdges] = useState()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        new SellintuService().sellFlowConfigurationGet()
            .then((response) => {
                // @ts-ignore
                setNodes(response.data.first)
                // @ts-ignore
                setEdges(response.data.second)

                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                if (error.response.status === 401) {
                    navigate("/login")
                }
            });
    }, [])

    return (
        <>
            {loading ? (
                <div className="card-body d-flex justify-content-center align-items-center">
                    <Spinner size="super-large" variant="secondary"/>
                </div>
            ) : <SellFlow lolNodes={nodes} lolEdges={edges}></SellFlow>}
        </>)
}


// @ts-ignore
const SellFlow = ({lolNodes, lolEdges}) => {
    // @ts-ignore
    const [nodes, setNodes, onNodesChange] = useNodesState(lolNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(lolEdges);
    const onConnect = useCallback((params: Edge<any> | Connection) => setEdges((eds) => addEdge(params, eds)), []);

    // we are using a bit of a shortcut here to adjust the edge type
    // this could also be done with a custom edge for example
    const edgesWithUpdatedTypes = edges.map((edge) => {
        if (edge.sourceHandle) {
            // @ts-ignore
            edge.animated = true
            edge.markerEnd = {
                type: MarkerType.ArrowClosed,
            }
            // edge.type = nodes.find((node) => node.type === 'mirakl').data.selects[edge.sourceHandle];
        }

        return edge;
    });

    const [showSaveButton, setShowSaveButton] = useState(true)

    const nodesUpdates = nodes.map((node) => {
        return node;
    })
    return (
        <ReactFlow
            nodes={nodesUpdates}
            edges={edgesWithUpdatedTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={onInit}
            fitView={false}
            attributionPosition="top-right"
            nodeTypes={nodeTypes}
        >
            <MiniMap style={minimapStyle} zoomable pannable/>
            <Controls/>
            <Background color="#2B6CB0" gap={16} offset={1}/>
            <Panel position="top-left">
                <AddNodePanel showSaveButton={showSaveButton} setShowSaveButton={setShowSaveButton}/>
            </Panel>

        </ReactFlow>
    );
}

export default SellFlowPage;
