import React, {useState} from 'react';

function CollapseMenu({content}) {

    const [isCollapsed, setIsCollapsed] = useState(false);
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        // todo: include when editing configuration is enabled
        <>
            <div className={"custom-node__button"}>
                {/*<button*/}
                {/*    onClick={toggleCollapse}>*/}
                {/*    {isCollapsed ? 'Configuration' : 'Save'}*/}
                {/*</button>*/}
            </div>
            {!isCollapsed && (content)}
        </>);
}

export default CollapseMenu;
