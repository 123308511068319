import React from "react"
import IconProps from "./types";

const ChevronDownIcon: React.FC<IconProps> = ({
                                                  size = "24px",
                                                  color = "currentColor",
                                                  ...attributes
                                              }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...attributes}
        >
            <path
                d="M4 6L8 10L12 6"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default ChevronDownIcon
