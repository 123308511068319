import React, {useState} from "react"
import {useForm} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Button from "../../fundamentals/button"
import SigninInput from "../../molecules/input-signin"
import SellintuService from "../../../services/sellintu-service";


type FormValues = {
    email: string
    password: string
}

type AppFormValue = {
    id: string
}

type LoginCardProps = {
    toResetPassword: () => void
}

const LoginCard: React.FC<LoginCardProps> = ({toResetPassword}) => {
    const [isInvalidLogin, setIsInvalidLogin] = useState(false)
    const [isLoadingLogin, setIsLoadingLogin] = useState(false)
    const {register, handleSubmit: userSubmit, reset} = useForm<FormValues>()
    const {
        register: registerAppId,
        handleSubmit: appIdSubmit,
        reset: resetAppId,
    } = useForm<AppFormValue>()
    const navigate = useNavigate()

    const onUserSubmit = (values: FormValues) => {
        setIsInvalidLogin(false)
        setIsLoadingLogin(true)
        // todo ACOMULT
        new SellintuService()
            .authUser(values.email, values.password)
            .then((response) => {
                setIsLoadingLogin(false)
                navigate("/a/orders")
            })
            .catch((error) => {
                setIsLoadingLogin(false)
                setIsInvalidLogin(true)
            });

    }
    return (
        <form onSubmit={userSubmit(onUserSubmit)}>
            <div className="flex flex-col items-center">
          <span className="inter-2xlarge-semibold text-grey-0 mt-4">
            Welcome to Sellintu
          </span>
                <span className="inter-base-regular mb-xlarge text-grey-30">
            Log in to your account below
          </span>
                <SigninInput
                    placeholder="Email or user..."
                    {...register("email", {required: true})}
                    autoComplete="email"
                />
                <span className="inter-small-regular text-grey-0 mt-8 cursor-pointer"/>
                <SigninInput
                    placeholder="Password..."
                    type={"password"}
                    {...register("password", {required: true})}
                    autoComplete="current-password"
                />
                {isInvalidLogin && (
                    <span className="inter-small-regular mt-2 w-full text-rose-50">
              These credentials do not match our records, make sure that your
              application id is correct as well as your username and password.
            </span>
                )}
                <span className="inter-small-regular text-grey-0 mt-8 cursor-pointer"/>
                <Button
                    className="inter-base-regular rounded-rounded mt-4 w-[320px]"
                    variant="primary"
                    size="large"
                    type="submit"
                    loading={isLoadingLogin}
                >
                    Continue
                </Button>
                <Button
                    className="inter-base-regular rounded-rounded mt-4 w-[320px]"
                    variant="secondary"
                    size="medium"
                    type="button"
                    onClick={toResetPassword}
                >
                    Register
                </Button>
            </div>
        </form>
    )
}

export default LoginCard
