import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import '../../../style/table.css';

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'
import {Link} from "react-router-dom";
import PaginationBar from "./pagination";
import StatusFilter from './statusFilter';
import formatTimeToDisplay from "../../../services/time-formatter";
import BadgeStatus from "../../atoms/badge-status";

type Order = {
    orderId: string
    shopId: string
    warehouseId: string
    status: 'shipped' | 'shipping' | 'delayed' | 'error'
    createdAt: Date
}

// @ts-ignore
function AscDescTable({orders}) {

    const [sorting, setSorting] = React.useState<SortingState>([{"id": "createdAt", "desc": true}])

    const columns = React.useMemo<ColumnDef<Order>[]>(
        () => [
            {
                accessorFn: row => row.orderId,
                id: 'orderId',
                cell: info => info.getValue(),
                header: () => <span>Id</span>,
                footer: props => props.column.id,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                cell: info => {
                    let status = info.getValue() as string
                    return (< BadgeStatus
                        orderStatus={status}
                        activated={true}/>)
                },
                footer: props => props.column.id,
            },
            {
                accessorKey: 'shopId',
                header: () => <span>Sales channel</span>,
                footer: props => props.column.id,
            },
            {
                accessorKey: 'warehouseId',
                header: 'Store',
                footer: props => props.column.id,
            },
            {
                accessorKey: 'createdAt',
                header: 'Created At',
                cell: info => {
                    let dateString = info.getValue() as string
                    return (<span> {formatTimeToDisplay(dateString)}</span>)
                },
            },
            {
                id: 'empty',
                accessorFn: row => row.orderId,
                header: () => <span> </span>,
                cell: props => {
                    return (<Link
                        target={"_blank"}
                        to={`/a/orders/` + props.getValue()}
                        className="px-base rounded-rounded flex items-center justify-between py-1.5">
                        Open
                    </Link>)
                }
            }
        ],
        []
    )

    const [data] = React.useState(() => orders)

    const table = useReactTable({
        data,
        columns,
        state: {sorting,},
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        debugTable: false,
    })

    const [filters, setFilters] = useState({
        'shipped': false,
        'shipping': true,
        'delayed': false,
        'error': false
    });

    return (
        <div className="p-2">
            <PaginationBar table={table}></PaginationBar>
            <div className="h-2"/>
            <table>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            // @ts-ignore
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted() as string] ?? '  '}
                                        </div>
                                    )}
                                    {header.id === "status" ? (
                                        <div>
                                            <StatusFilter column={header.column} filters={filters}
                                                          setFilters={setFilters}/>
                                        </div>
                                    ) : null}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel()
                    .rows
                    .map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="h-2"/>
            <PaginationBar table={table}></PaginationBar>
            <div>{table.getRowModel().flatRows.length} Orders</div>
        </div>
    )
}

export default AscDescTable;
