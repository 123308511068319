import React, {memo} from 'react';
import {Position} from 'reactflow';
import TextBox from "../TextBox";
import Select from "../Select";
import LogoTitleHandle from "../LogoTitleHandle";
import CollapseMenu from "../CollapseMenu";

function MiraklNode({id, data}) {
    return (
        <>
            <LogoTitleHandle handlerPosition={Position.Right}
                             handlerType={"source"}
                             title={"Mirakl"}
                             logoSrc={"../../logos/mirakl.jpg"}/>

            <CollapseMenu
                content={

                    <div className="custom-node__body">
                        <div className="custom-node__header__text__medium">{data.name}</div>
                        {/*// todo: include when editing configuration is enabled*/}
                        {/*{Object.keys(data.selects).map((conf) => (*/}
                        {/*    <Select key={conf}*/}
                        {/*            nodeId={id}*/}
                        {/*            initialConf={data.selects[conf].selected}*/}
                        {/*            confId={conf}*/}
                        {/*            options={data.selects[conf].options}*/}
                        {/*            userTextHint={data.selects[conf].hintText}/>*/}
                        {/*))}*/}
                        {/*<TextBox hint={"API URL"}/>*/}
                        {/*<TextBox hint={"API KEY"} password={true}/>*/}
                    </div>}
            />
        </>
    );
}

export default memo(MiraklNode);
