import axios from "axios";
import {setupCache} from "axios-cache-interceptor";

const base_url = "https://api.sellintu.com"

const client = axios.create({baseURL: base_url});
const axiosCached = setupCache(axios);

function sellintuCacheGETRequest(path) {
    const options = {
        withCredentials: true,
        url: path,
        json: true,
    }
    return axiosCached.get(base_url + path, options)
}

function sellintuAPIRequest(method, path = "", payload = {}) {
    const options = {
        method,
        withCredentials: true,
        url: path,
        data: payload,
        json: true,
    }
    return client(options)
}

class SellintuService {
    authUser(username, password) {
        let bodyFormData = new FormData();
        bodyFormData.append("username", username)
        bodyFormData.append("password", password)
        return sellintuAPIRequest("post", "/admin/login", bodyFormData)
    }

    logout() {
        return sellintuAPIRequest("get", "/admin/logout")
    }

    register(usernameRq) {
        return sellintuAPIRequest("post", "/admin/register", usernameRq)
    }

    ordersGet() {
        // return sellintuCacheGETRequest("/admin/orders")
        return sellintuAPIRequest("get", "/admin/orders")
    }

    orderGet(id) {
        return sellintuAPIRequest("get", "/admin/order?orderId=" + id)
    }

    sellFlowConfigurationGet() {
        return sellintuAPIRequest("get", "/admin/sellflow")
    }

    pingAuth() {
        return sellintuAPIRequest("get", "/admin/orders")
    }

    range(len) {
        const arr = []
        for (let i = 0; i < len; i++) {
            arr.push(i)
        }
        return arr
    }

    makeData() {
        return this.range(1000).map((d) => {
            return {
                id: "10", createdAt: new Date(), shop: "shopify", status: "shipping", warehouse: "FBA"
            }
        }).concat({
            id: "3", createdAt: new Date(), shop: "shopify", status: "error", warehouse: "FBA"
        })
    }
}

export default SellintuService
