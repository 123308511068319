import React, {useState} from "react"
import {useForm} from "react-hook-form"
import CheckCircleIcon from "../../fundamentals/icons/check-circle-icon"
import SigninInput from "../../molecules/input-signin"
import SellintuService from "../../../services/sellintu-service";
import Button from "../../fundamentals/button";

type ResetTokenCardProps = {
    goBack: () => void
}

type FormValues = {
    email: string
}

const checkMail = /^\S+@\S+$/i

const RegisterCard: React.FC<ResetTokenCardProps> = ({goBack}) => {
    const [unrecognizedEmail, setUnrecognizedEmail] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [mailSent, setSentMail] = useState(false)
    const {register, handleSubmit} = useForm<FormValues>()

    const onSubmit = (values: FormValues) => {
        if (!checkMail.test(values.email)) {
            setInvalidEmail(true)
            return
        }
        setInvalidEmail(false)
        setUnrecognizedEmail(false)
        setSentMail(true)
        new SellintuService().register(
            values
        ).then((_) => {
            setSentMail(true)
        })
            .catch((error) => {
                console.log(error)
                setUnrecognizedEmail(true)
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col items-center">
        <span className="inter-2xlarge-semibold mt-base text-grey-0">
          Register your account
        </span>
                <span className="inter-base-regular mt-xsmall text-grey-30 text-center">
          Enter your email address below, and we will send you
          <br/>
          instructions on how to activate your account.
        </span>
                {!mailSent ? (
                    <>
                        <SigninInput
                            placeholder="info@sellintu.com..."
                            {...register("email", {required: true})}
                            className="mt-xlarge mb-0"
                        />
                        {unrecognizedEmail && (
                            <div className="mt-xsmall w-[318px]">
                <span className="inter-small-regular text-left text-rose-50">
                  Not a valid email address
                </span>
                            </div>
                        )}
                        {invalidEmail && (
                            <div className="mt-xsmall w-[318px]">
                <span className="inter-small-regular text-left text-rose-50">
                  Not a valid email address
                </span>
                            </div>
                        )}
                        <Button
                            className="inter-base-regular rounded-rounded mt-4 w-[320px]"
                            variant="primary"
                            size="large"
                            type="submit"
                        >
                            Register
                        </Button>
                    </>
                ) : (
                    <div className="mt-large gap-x-small rounded-rounded bg-violet-10 p-base text-violet-60 flex">
                        <div>
                            <CheckCircleIcon size={20}/>
                        </div>
                        <div className="gap-y-2xsmall flex flex-col">
              <span className="inter-small-semibold">
                Successfully sent you an email
              </span>
                            <span className="inter-small-regular">
                We will contact you with further steps to active your account.
                Thank you!
              </span>
                        </div>
                    </div>
                )}
                <span
                    className="inter-small-regular text-grey-0 mt-8 cursor-pointer"
                    onClick={goBack}
                >
          Go back to sign in
        </span>
            </div>
        </form>
    )
}

export default RegisterCard
