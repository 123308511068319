import React, {memo} from 'react';
import {Position} from 'reactflow';
import LogoTitleHandle from "../LogoTitleHandle";
import CollapseMenu from "../CollapseMenu";

function AmazonNode() {
    return (
        <>
            <LogoTitleHandle handlerPosition={Position.Left}
                             handlerType={"target"}
                             title={"FBA"}
                             logoSrc={"../../logos/fba.png"}/>
            <CollapseMenu content={<div className="custom-node__body"></div>}
            />
        </>
    );
}

export default memo(AmazonNode);
