import {PropsWithChildren} from "react"
import {Toaster} from "react-hot-toast"

const PublicLayout = ({children}: PropsWithChildren) => {
    return (
        <div
            className="flex min-h-screen flex-col items-center justify-center"
            style={{
                background: "#0e1425",
            }}
        >
            <Toaster
                containerStyle={{
                    top: 24,
                    left: 24,
                    bottom: 24,
                    right: 24,
                }}
                position="bottom-right"
            />
            <div className="mb-large">
                <Logo/>
            </div>
            {children}
            <span className="inter-small-regular text-grey-0 mt-8 cursor-pointer"/>
            <div className="text-grey-0 inter-base-regular pb-12">
                © Sellintu <span>&#183;</span>{" "}
                <a
                    style={{color: "inherit", textDecoration: "none"}}
                    href="mailto:info@sellintu.com"
                >
                    Contact
                </a>
            </div>
        </div>
    )
}

const Logo = () => {
    return (
        <div
            className="w-6xlarge h-6xlarge flex items-center justify-center rounded-full bg-gradient-to-t from-[#0] via-[#151718] to-[#0]">
            <SVG/>
        </div>
    )
}

const SVG = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="520" height="285" viewBox="0 0 520 285" fill="none"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M237.983 243.663C234.843 246.349 231.699 249.039 228.29 251.356C212.281 262.238 191.483 270.8 171.995 272.197C150.349 273.749 128.68 272.263 108.301 263.958C91.7197 257.201 76.6243 247.105 63.9934 234.433C52.3675 222.77 42.7271 208.959 36.2534 193.817C7.54153 126.659 40.6204 46.7615 109.224 19.919C129.557 11.9632 151.369 9.96929 172.918 12.1239C192.267 14.0586 212.255 22.1102 228.29 32.8936C231.505 35.0556 234.486 37.6675 237.461 40.2738C241.054 43.4213 244.637 46.5609 248.612 48.8904C245.631 42.8459 238.803 37.3696 233.356 33.0017C232.891 32.6281 232.435 32.2627 231.993 31.9057C217.023 19.8232 199.787 10.3726 181.226 5.13447C98.1444 -18.3122 6.83139 41.2197 0.382267 129.2C-6.19425 218.917 73.05 297.868 163.687 282.733C187.182 278.81 207.985 270.005 227.381 256.053C234.449 250.967 244.669 243.353 248.612 235.356C244.849 237.789 241.419 240.724 237.983 243.663Z"
                fill="white"/>
            <path
                d="M81.5314 59.9675C120.791 34.6535 164.942 24.2136 207.996 49.0243C219.665 55.749 229.631 64.5124 238.325 74.7371C239.671 76.3202 240.982 78.5251 242.348 80.8229C244.8 84.9467 247.43 89.3698 250.759 91.0343C256.032 93.6716 266.112 92.3424 271.689 91.3528C248.899 36.6271 179.328 13.9207 125.84 31.8188C111.986 36.4544 88.4943 46.1025 81.5314 59.9675Z"
                fill="white"/>
            <path
                d="M125.84 252.428C112.062 247.817 88.3549 238.112 81.5314 224.279C84.1 225.878 86.5956 227.512 89.072 229.134C96.6825 234.117 104.111 238.981 112.917 242.358C145.388 254.81 182.128 250.92 211.688 232.822C222.14 226.422 231.333 218.142 238.967 208.586C240.041 207.24 241.18 205.381 242.396 203.398C245.033 199.094 248.03 194.202 251.518 192.679C255.175 191.082 260.559 191.741 265.429 192.336C267.704 192.615 269.868 192.879 271.689 192.893C246.065 246.522 181.362 271.007 125.84 252.428Z"
                fill="white"/>
            <path
                d="M88.432 176.768C91.248 176.768 93.552 176.555 95.344 176.128C97.2213 175.616 98.7147 174.976 99.824 174.208C100.933 173.355 101.701 172.373 102.128 171.264C102.555 170.155 102.768 168.917 102.768 167.552C102.768 164.651 101.403 162.261 98.672 160.384C95.9413 158.421 91.248 156.331 84.592 154.112C81.6907 153.088 78.7893 151.936 75.888 150.656C72.9867 149.291 70.384 147.627 68.08 145.664C65.776 143.616 63.8987 141.184 62.448 138.368C60.9973 135.467 60.272 131.968 60.272 127.872C60.272 123.776 61.04 120.107 62.576 116.864C64.112 113.536 66.288 110.72 69.104 108.416C71.92 106.112 75.3333 104.363 79.344 103.168C83.3547 101.888 87.8773 101.248 92.912 101.248C98.8853 101.248 104.048 101.888 108.4 103.168C112.752 104.448 116.336 105.856 119.152 107.392L113.392 123.136C110.917 121.856 108.144 120.747 105.072 119.808C102.085 118.784 98.4587 118.272 94.192 118.272C89.4133 118.272 85.9573 118.955 83.824 120.32C81.776 121.6 80.752 123.605 80.752 126.336C80.752 127.957 81.136 129.323 81.904 130.432C82.672 131.541 83.7387 132.565 85.104 133.504C86.5547 134.357 88.176 135.168 89.968 135.936C91.8453 136.619 93.8933 137.344 96.112 138.112C100.72 139.819 104.731 141.525 108.144 143.232C111.557 144.853 114.373 146.773 116.592 148.992C118.896 151.211 120.603 153.813 121.712 156.8C122.821 159.787 123.376 163.413 123.376 167.68C123.376 175.957 120.475 182.4 114.672 187.008C108.869 191.531 100.123 193.792 88.432 193.792C84.5067 193.792 80.9653 193.536 77.808 193.024C74.6507 192.597 71.8347 192.043 69.36 191.36C66.9707 190.677 64.88 189.952 63.088 189.184C61.3813 188.416 59.9307 187.691 58.736 187.008L64.368 171.136C67.0133 172.587 70.256 173.909 74.096 175.104C78.0213 176.213 82.8 176.768 88.432 176.768Z"
                fill="white"/>
            <path
                d="M134.26 158.848C134.26 152.875 135.156 147.669 136.948 143.232C138.825 138.709 141.257 134.955 144.244 131.968C147.231 128.981 150.644 126.72 154.484 125.184C158.409 123.648 162.42 122.88 166.516 122.88C176.073 122.88 183.625 125.824 189.172 131.712C194.719 137.515 197.492 146.091 197.492 157.44C197.492 158.549 197.449 159.787 197.364 161.152C197.279 162.432 197.193 163.584 197.108 164.608H153.844C154.271 168.533 156.105 171.648 159.348 173.952C162.591 176.256 166.943 177.408 172.404 177.408C175.903 177.408 179.316 177.109 182.644 176.512C186.057 175.829 188.831 175.019 190.964 174.08L193.524 189.568C192.5 190.08 191.135 190.592 189.428 191.104C187.721 191.616 185.801 192.043 183.668 192.384C181.62 192.811 179.401 193.152 177.012 193.408C174.623 193.664 172.233 193.792 169.844 193.792C163.785 193.792 158.495 192.896 153.972 191.104C149.535 189.312 145.823 186.88 142.836 183.808C139.935 180.651 137.759 176.939 136.308 172.672C134.943 168.405 134.26 163.797 134.26 158.848ZM179.06 151.552C178.975 149.931 178.676 148.352 178.164 146.816C177.737 145.28 177.012 143.915 175.988 142.72C175.049 141.525 173.812 140.544 172.276 139.776C170.825 139.008 168.991 138.624 166.772 138.624C164.639 138.624 162.804 139.008 161.268 139.776C159.732 140.459 158.452 141.397 157.428 142.592C156.404 143.787 155.593 145.195 154.996 146.816C154.484 148.352 154.1 149.931 153.844 151.552H179.06Z"
                fill="white"/>
            <path
                d="M239.09 193.28C233.543 193.195 229.021 192.597 225.522 191.488C222.109 190.379 219.378 188.843 217.33 186.88C215.367 184.832 214.002 182.4 213.234 179.584C212.551 176.683 212.21 173.44 212.21 169.856V95.744L231.282 92.672V166.016C231.282 167.723 231.41 169.259 231.666 170.624C231.922 171.989 232.391 173.141 233.074 174.08C233.842 175.019 234.909 175.787 236.274 176.384C237.639 176.981 239.474 177.365 241.778 177.536L239.09 193.28Z"
                fill="white"/>
            <path
                d="M279.59 193.28C274.043 193.195 269.521 192.597 266.022 191.488C262.609 190.379 259.878 188.843 257.83 186.88C255.867 184.832 254.502 182.4 253.734 179.584C253.051 176.683 252.71 173.44 252.71 169.856V95.744L271.782 92.672V166.016C271.782 167.723 271.91 169.259 272.166 170.624C272.422 171.989 272.891 173.141 273.574 174.08C274.342 175.019 275.409 175.787 276.774 176.384C278.139 176.981 279.974 177.365 282.278 177.536L279.59 193.28Z"
                fill="white"/>
            <path
                d="M312.282 192H293.21V124.672H312.282V192ZM314.074 105.216C314.074 108.715 312.922 111.488 310.618 113.536C308.399 115.499 305.754 116.48 302.682 116.48C299.61 116.48 296.922 115.499 294.618 113.536C292.399 111.488 291.29 108.715 291.29 105.216C291.29 101.717 292.399 98.9867 294.618 97.024C296.922 94.976 299.61 93.952 302.682 93.952C305.754 93.952 308.399 94.976 310.618 97.024C312.922 98.9867 314.074 101.717 314.074 105.216Z"
                fill="white"/>
            <path
                d="M330.21 126.976C333.453 126.037 337.634 125.184 342.754 124.416C347.874 123.563 353.25 123.136 358.882 123.136C364.599 123.136 369.335 123.904 373.09 125.44C376.93 126.891 379.959 128.981 382.178 131.712C384.397 134.443 385.975 137.685 386.914 141.44C387.853 145.195 388.322 149.376 388.322 153.984V192H369.25V156.288C369.25 150.144 368.439 145.792 366.818 143.232C365.197 140.672 362.167 139.392 357.73 139.392C356.365 139.392 354.914 139.477 353.378 139.648C351.842 139.733 350.477 139.861 349.282 140.032V192H330.21V126.976Z"
                fill="white"/>
            <path
                d="M404.945 107.904L424.017 104.832V124.672H446.929V140.544H424.017V164.224C424.017 168.235 424.7 171.435 426.065 173.824C427.516 176.213 430.374 177.408 434.641 177.408C436.689 177.408 438.78 177.237 440.913 176.896C443.132 176.469 445.137 175.915 446.929 175.232L449.617 190.08C447.313 191.019 444.753 191.829 441.937 192.512C439.121 193.195 435.665 193.536 431.569 193.536C426.364 193.536 422.054 192.853 418.641 191.488C415.228 190.037 412.497 188.075 410.449 185.6C408.401 183.04 406.95 179.968 406.097 176.384C405.329 172.8 404.945 168.832 404.945 164.48V107.904Z"
                fill="white"/>
            <path
                d="M519.932 189.696C516.689 190.635 512.508 191.488 507.388 192.256C502.268 193.109 496.892 193.536 491.26 193.536C485.543 193.536 480.764 192.768 476.924 191.232C473.169 189.696 470.183 187.563 467.964 184.832C465.745 182.016 464.167 178.688 463.228 174.848C462.289 171.008 461.82 166.784 461.82 162.176V124.672H480.892V159.872C480.892 166.016 481.703 170.453 483.324 173.184C484.945 175.915 487.975 177.28 492.412 177.28C493.777 177.28 495.228 177.237 496.764 177.152C498.3 176.981 499.665 176.811 500.86 176.64V124.672H519.932V189.696Z"
                fill="white"/>
            <path
                d="M237.983 243.663C234.843 246.349 231.699 249.039 228.29 251.356C212.281 262.238 191.483 270.8 171.995 272.197C150.349 273.749 128.68 272.263 108.301 263.958C91.7197 257.201 76.6243 247.105 63.9934 234.433C52.3675 222.77 42.7271 208.959 36.2534 193.817C7.54153 126.659 40.6204 46.7615 109.224 19.919C129.557 11.9632 151.369 9.96929 172.918 12.1239C192.267 14.0586 212.255 22.1102 228.29 32.8936C231.505 35.0556 234.486 37.6675 237.461 40.2738C241.054 43.4213 244.637 46.5609 248.612 48.8904C245.631 42.8459 238.803 37.3696 233.356 33.0017C232.891 32.6281 232.435 32.2627 231.993 31.9057C217.023 19.8232 199.787 10.3726 181.226 5.13447C98.1444 -18.3122 6.83139 41.2197 0.382267 129.2C-6.19425 218.917 73.05 297.868 163.687 282.733C187.182 278.81 207.985 270.005 227.381 256.053C234.449 250.967 244.669 243.353 248.612 235.356C244.849 237.789 241.419 240.724 237.983 243.663Z"
                fill="white"/>
            <path
                d="M81.5314 59.9675C120.791 34.6535 164.942 24.2136 207.996 49.0243C219.665 55.749 229.631 64.5124 238.325 74.7371C239.671 76.3202 240.982 78.5251 242.348 80.8229C244.8 84.9467 247.43 89.3698 250.759 91.0343C256.032 93.6716 266.112 92.3424 271.689 91.3528C248.899 36.6271 179.328 13.9207 125.84 31.8188C111.986 36.4544 88.4943 46.1025 81.5314 59.9675Z"
                fill="white"/>
            <path
                d="M125.84 252.428C112.062 247.817 88.3549 238.112 81.5314 224.279C84.1 225.878 86.5956 227.512 89.072 229.134C96.6825 234.117 104.111 238.981 112.917 242.358C145.388 254.81 182.128 250.92 211.688 232.822C222.14 226.422 231.333 218.142 238.967 208.586C240.041 207.24 241.18 205.381 242.396 203.398C245.033 199.094 248.03 194.202 251.518 192.679C255.175 191.082 260.559 191.741 265.429 192.336C267.704 192.615 269.868 192.879 271.689 192.893C246.065 246.522 181.362 271.007 125.84 252.428Z"
                fill="white"/>
            <path
                d="M88.432 176.768C91.248 176.768 93.552 176.555 95.344 176.128C97.2213 175.616 98.7147 174.976 99.824 174.208C100.933 173.355 101.701 172.373 102.128 171.264C102.555 170.155 102.768 168.917 102.768 167.552C102.768 164.651 101.403 162.261 98.672 160.384C95.9413 158.421 91.248 156.331 84.592 154.112C81.6907 153.088 78.7893 151.936 75.888 150.656C72.9867 149.291 70.384 147.627 68.08 145.664C65.776 143.616 63.8987 141.184 62.448 138.368C60.9973 135.467 60.272 131.968 60.272 127.872C60.272 123.776 61.04 120.107 62.576 116.864C64.112 113.536 66.288 110.72 69.104 108.416C71.92 106.112 75.3333 104.363 79.344 103.168C83.3547 101.888 87.8773 101.248 92.912 101.248C98.8853 101.248 104.048 101.888 108.4 103.168C112.752 104.448 116.336 105.856 119.152 107.392L113.392 123.136C110.917 121.856 108.144 120.747 105.072 119.808C102.085 118.784 98.4587 118.272 94.192 118.272C89.4133 118.272 85.9573 118.955 83.824 120.32C81.776 121.6 80.752 123.605 80.752 126.336C80.752 127.957 81.136 129.323 81.904 130.432C82.672 131.541 83.7387 132.565 85.104 133.504C86.5547 134.357 88.176 135.168 89.968 135.936C91.8453 136.619 93.8933 137.344 96.112 138.112C100.72 139.819 104.731 141.525 108.144 143.232C111.557 144.853 114.373 146.773 116.592 148.992C118.896 151.211 120.603 153.813 121.712 156.8C122.821 159.787 123.376 163.413 123.376 167.68C123.376 175.957 120.475 182.4 114.672 187.008C108.869 191.531 100.123 193.792 88.432 193.792C84.5067 193.792 80.9653 193.536 77.808 193.024C74.6507 192.597 71.8347 192.043 69.36 191.36C66.9707 190.677 64.88 189.952 63.088 189.184C61.3813 188.416 59.9307 187.691 58.736 187.008L64.368 171.136C67.0133 172.587 70.256 173.909 74.096 175.104C78.0213 176.213 82.8 176.768 88.432 176.768Z"
                fill="white"/>
            <path
                d="M134.26 158.848C134.26 152.875 135.156 147.669 136.948 143.232C138.825 138.709 141.257 134.955 144.244 131.968C147.231 128.981 150.644 126.72 154.484 125.184C158.409 123.648 162.42 122.88 166.516 122.88C176.073 122.88 183.625 125.824 189.172 131.712C194.719 137.515 197.492 146.091 197.492 157.44C197.492 158.549 197.449 159.787 197.364 161.152C197.279 162.432 197.193 163.584 197.108 164.608H153.844C154.271 168.533 156.105 171.648 159.348 173.952C162.591 176.256 166.943 177.408 172.404 177.408C175.903 177.408 179.316 177.109 182.644 176.512C186.057 175.829 188.831 175.019 190.964 174.08L193.524 189.568C192.5 190.08 191.135 190.592 189.428 191.104C187.721 191.616 185.801 192.043 183.668 192.384C181.62 192.811 179.401 193.152 177.012 193.408C174.623 193.664 172.233 193.792 169.844 193.792C163.785 193.792 158.495 192.896 153.972 191.104C149.535 189.312 145.823 186.88 142.836 183.808C139.935 180.651 137.759 176.939 136.308 172.672C134.943 168.405 134.26 163.797 134.26 158.848ZM179.06 151.552C178.975 149.931 178.676 148.352 178.164 146.816C177.737 145.28 177.012 143.915 175.988 142.72C175.049 141.525 173.812 140.544 172.276 139.776C170.825 139.008 168.991 138.624 166.772 138.624C164.639 138.624 162.804 139.008 161.268 139.776C159.732 140.459 158.452 141.397 157.428 142.592C156.404 143.787 155.593 145.195 154.996 146.816C154.484 148.352 154.1 149.931 153.844 151.552H179.06Z"
                fill="white"/>
            <path
                d="M239.09 193.28C233.543 193.195 229.021 192.597 225.522 191.488C222.109 190.379 219.378 188.843 217.33 186.88C215.367 184.832 214.002 182.4 213.234 179.584C212.551 176.683 212.21 173.44 212.21 169.856V95.744L231.282 92.672V166.016C231.282 167.723 231.41 169.259 231.666 170.624C231.922 171.989 232.391 173.141 233.074 174.08C233.842 175.019 234.909 175.787 236.274 176.384C237.639 176.981 239.474 177.365 241.778 177.536L239.09 193.28Z"
                fill="white"/>
            <path
                d="M279.59 193.28C274.043 193.195 269.521 192.597 266.022 191.488C262.609 190.379 259.878 188.843 257.83 186.88C255.867 184.832 254.502 182.4 253.734 179.584C253.051 176.683 252.71 173.44 252.71 169.856V95.744L271.782 92.672V166.016C271.782 167.723 271.91 169.259 272.166 170.624C272.422 171.989 272.891 173.141 273.574 174.08C274.342 175.019 275.409 175.787 276.774 176.384C278.139 176.981 279.974 177.365 282.278 177.536L279.59 193.28Z"
                fill="white"/>
            <path
                d="M312.282 192H293.21V124.672H312.282V192ZM314.074 105.216C314.074 108.715 312.922 111.488 310.618 113.536C308.399 115.499 305.754 116.48 302.682 116.48C299.61 116.48 296.922 115.499 294.618 113.536C292.399 111.488 291.29 108.715 291.29 105.216C291.29 101.717 292.399 98.9867 294.618 97.024C296.922 94.976 299.61 93.952 302.682 93.952C305.754 93.952 308.399 94.976 310.618 97.024C312.922 98.9867 314.074 101.717 314.074 105.216Z"
                fill="white"/>
            <path
                d="M330.21 126.976C333.453 126.037 337.634 125.184 342.754 124.416C347.874 123.563 353.25 123.136 358.882 123.136C364.599 123.136 369.335 123.904 373.09 125.44C376.93 126.891 379.959 128.981 382.178 131.712C384.397 134.443 385.975 137.685 386.914 141.44C387.853 145.195 388.322 149.376 388.322 153.984V192H369.25V156.288C369.25 150.144 368.439 145.792 366.818 143.232C365.197 140.672 362.167 139.392 357.73 139.392C356.365 139.392 354.914 139.477 353.378 139.648C351.842 139.733 350.477 139.861 349.282 140.032V192H330.21V126.976Z"
                fill="white"/>
            <path
                d="M404.945 107.904L424.017 104.832V124.672H446.929V140.544H424.017V164.224C424.017 168.235 424.7 171.435 426.065 173.824C427.516 176.213 430.374 177.408 434.641 177.408C436.689 177.408 438.78 177.237 440.913 176.896C443.132 176.469 445.137 175.915 446.929 175.232L449.617 190.08C447.313 191.019 444.753 191.829 441.937 192.512C439.121 193.195 435.665 193.536 431.569 193.536C426.364 193.536 422.054 192.853 418.641 191.488C415.228 190.037 412.497 188.075 410.449 185.6C408.401 183.04 406.95 179.968 406.097 176.384C405.329 172.8 404.945 168.832 404.945 164.48V107.904Z"
                fill="white"/>
            <path
                d="M519.932 189.696C516.689 190.635 512.508 191.488 507.388 192.256C502.268 193.109 496.892 193.536 491.26 193.536C485.543 193.536 480.764 192.768 476.924 191.232C473.169 189.696 470.183 187.563 467.964 184.832C465.745 182.016 464.167 178.688 463.228 174.848C462.289 171.008 461.82 166.784 461.82 162.176V124.672H480.892V159.872C480.892 166.016 481.703 170.453 483.324 173.184C484.945 175.915 487.975 177.28 492.412 177.28C493.777 177.28 495.228 177.237 496.764 177.152C498.3 176.981 499.665 176.811 500.86 176.64V124.672H519.932V189.696Z"
                fill="white"/>

        </svg>
    )
}

export default PublicLayout
