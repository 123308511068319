import Layout from "../../components/templates/layout/layout";
import {useParams} from "react-router-dom";
import React from "react";
import OrderCard from "../../components/organisms/order-card";

function OrderPage() {
    const params = useParams()["*"]
    // @ts-ignore
    return (<> <Layout><OrderCard id={params}> </OrderCard> </Layout> </>)
}

export default OrderPage;
