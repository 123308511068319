import React, {useCallback} from 'react';
import {useReactFlow} from "reactflow";

const getNodeId = () => `randomnode_${+new Date()}`;

function AddNodePanel({showSaveButton, setShowSaveButton}) {

    const {getNodes, setNodes, getEdges} = useReactFlow();

    const onAdd = useCallback(() => {
        const newNode = {
            id: getNodeId(), type: 'shopify', position: {
                x: (Math.random() * 500) + 500, y: Math.random() * 500,
            }, data: {
                apiKey: "", marketPlaceId: "", selects: {
                    'conf-sync-order': {
                        hintText: "How to sync order?", selected: 'create', options: ['draft&create', 'create']
                    }, 'conf-inventory': {
                        hintText: "How to sync order?", selected: 'create', options: ['draft&create', 'create']
                    },
                },
            },
        };
        // @ts-ignore
        setNodes((nds) => nds.concat(newNode));
    }, [setNodes]);


    const onSave = useCallback(() => {
        // todo ACOMULT: API
        setShowSaveButton(false)
    }, [getNodes, getEdges]);

    return (<>
        <div>
            {/*<h1>Index</h1>*/}
            {/*<h2>Add new connection</h2>*/}
            {/*<button onClick={onAdd}>*/}
            {/*    <div>*/}
            {/*        <div className="add-node">*/}
            {/*            <img src={"../../logos/shopify.png"}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</button>*/}
            {/*{showSaveButton && (<button onClick={onSave}>save</button>)}*/}
        </div>
    </>);
}

export default AddNodePanel;
