import React from "react";
import BadgeStatus from "../../atoms/badge-status";


const defaultStatus = {
    'shipped': false,
    'shipping': false,
    'delayed': false,
    'error': false
}

// @ts-ignore
function StatusFilters({filters, setFilters}) {
    // @ts-ignore
    const toggleFilter = (filterName) => {
        // @ts-ignore
        setFilters((prevFilters) => ({
            ...prevFilters,
            // @ts-ignore
            [filterName]: !prevFilters[filterName]
        }));
    };
    // @ts-ignore
    const selectFilter = (filterName) => {
        // @ts-ignore
        setFilters(() => ({
            ...defaultStatus,
            // @ts-ignore
            [filterName]: true
        }));
    };

    const button = (filter: string) => {
        return <button key={filter} onClick={() => selectFilter(filter)}>
            <BadgeStatus
                orderStatus={filter}
                activated={filters[filter]}
            />
        </button>
    }
    return (
        <div>
            <div>
                {Object.keys(filters).filter((filter, index, filtes_) => (
                    index % 2 === 0
                )).map((filter) => (button(filter)))}
            </div>
            <div>
                {Object.keys(filters).filter((filter, index, filtes_) => (
                    index % 2 !== 0
                )).map((filter) => (button(filter)))}
            </div>
        </div>

    );
}

// {column}: { column: Column<any, any> }
// @ts-ignore
function StatusFilter({column, filters, setFilters}) {
    const columnFilterValue = column.getFilterValue()
    // @ts-ignore
    const newFilter = Object.keys(filters).find(key => filters[key] === true)
    if (columnFilterValue !== newFilter) {
        column.setFilterValue(newFilter)
    }
    return (
        <StatusFilters filters={filters} setFilters={setFilters}/>
    )
}

export default StatusFilter
