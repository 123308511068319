import React, {useEffect, useState} from 'react';
import Spinner from "../../atoms/spinner";
import SellintuService from "../../../services/sellintu-service";

type Order = {
    orderId: string
    shopId: string
    warehouseId: string
    status: 'shipped' | 'shipping' | 'delayed' | 'error'
    createdAt: Date
}

// @ts-ignore
const OrderCard = ({id}) => {
    // @ts-ignore
    const [data, setData]: [Order, (value: (((prevState: Order) => Order) | Order)) => void] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        new SellintuService().orderGet(id)
            .then((response) => {
                setData(response.data as Order);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    return (
        <div className="card">
            {loading ? (
                <div className="card-body d-flex justify-content-center align-items-center">
                    <Spinner size="super-large" variant="secondary"/>
                </div>
            ) : (<div className="card-body">
                <h5 className="card-title">Order Information</h5>
                <p className="card-text">
                    <strong>Order ID:</strong> {id}<br/>
                    <strong>Created Time:</strong> {data.createdAt}<br/>
                    <strong>Status:</strong> {data.status}<br/>
                    <strong>Sales channel:</strong> {data.shopId}<br/>
                    <strong>Store:</strong> {data.warehouseId}<br/>
                </p>
            </div>)}
        </div>
    );
};

export default OrderCard;


