

// const dateString = "2023-03-28T22:00:01Z";
function formatTimeToDisplay(dateString) {

    const parsedDate = new Date(dateString);

    const now = new Date();

    const isToday = now.toDateString() === parsedDate.toDateString();
    const isYesterday = now.getDate() - parsedDate.getDate() === 1 && now.getMonth() === parsedDate.getMonth() && now.getFullYear() === parsedDate.getFullYear();

    let formattedDate;

    if (isToday) {
        // Display hours and minutes if it's today
        formattedDate = parsedDate.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric'});
    } else if (isYesterday) {
        // Display "Yesterday" and hours and minutes if it's yesterday
        formattedDate = 'Yesterday ' + parsedDate.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric'});
    } else {
        // Display day, month, and year for other dates
        formattedDate = parsedDate.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
    }
    console.log(formattedDate);
    return formattedDate;
}


export default formatTimeToDisplay
