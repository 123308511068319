import React from "react";

type StatusProps = {
    orderStatus: string,
    activated: boolean
}

function MapBadgeStatus(status: string) {
    if (status === "shipped") {
        return "success"
    } else if (status === "shipping") {
        return "primary"
    } else if (status === "delayed") {
        return "warning"
    } else if (status === "error") {
        return "danger"
    }
}

const badgeClass = (status: string, activated: boolean) => {
    return activated ? 'badge rounded-pill text-bg-' + MapBadgeStatus(status)
        : 'badge rounded-pill text-bg-light';
}

function BadgeStatus(statusProps: StatusProps) {
    return (
        <span className={badgeClass(statusProps.orderStatus, statusProps.activated)}>{statusProps.orderStatus}</span>)
}

export default BadgeStatus
