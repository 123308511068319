import React, {Suspense} from "react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider,} from "react-router-dom";
import Spinner from "./components/atoms/spinner";
import Layout from "./components/templates/layout/layout";

import './style/global.css';
import './style/App.css';
import 'bootstrap/dist/css/bootstrap.css';

import OrderPage from "./domain/order";
import LoginPage from "./domain/login";
import OrdersPage from "./domain/orders";
import SellFlowPage from "./domain/sellflow";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<LoginPage/>}/>
            <Route path="a/orders" element={<OrdersPage/>}/>
            <Route path="a/orders/*" element={<OrderPage/>}/>
            <Route path="login" element={<LoginPage/>}/>
            <Route path="*" element={<> <h1>Notfound</h1> </>}/>
            <Route path="a/sellflow" element={<div style={{height: 700, width: 1200}}>
                <Layout> <SellFlowPage/> </Layout>
            </div>}/>
        </>
    ),
)

const Loading = () => (
    <div className="bg-grey-5 text-grey-90 flex h-screen w-full items-center justify-center">
        <Spinner variant="secondary"/>
    </div>
)

const App = () => {
    return (
        <Suspense fallback={<Loading/>}>
            <RouterProvider router={router}/>
        </Suspense>
    );
}

export default App;
