import React from 'react';
import {Handle} from "reactflow";

function LogoTitleHandle({title, logoSrc, handlerPosition, handlerType}) {

    return (
        <div className="custom-node__header">
            <div className="custom-node__logo">
                <img src={logoSrc}/>
                <div className="custom-node__header__text">{title}</div>
            </div>
            <div className="custom-node__handle">
                <Handle type={handlerType} position={handlerPosition} id={"smoothstep"}/>
            </div>
        </div>
    );
}

export default LogoTitleHandle;
