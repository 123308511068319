import React, {useEffect, useState} from "react"
import Spinner from "../../atoms/spinner"
import Input from "../../atoms/text-input"
import * as RadixDialog from "@radix-ui/react-dialog"
import KeyboardShortcuts from "./keyboard-shortcuts"
import clsx from "clsx"
import CrossIcon from "../../fundamentals/icons/cross-icon"
import Tooltip from "../../atoms/tooltip"
import {useDebounce} from "../../hooks/use-debounce";
import SearchIcon from "../../fundamentals/search-icon";
import OrderResults from "./results/order-results";
import useKeyboardNavigationList from "./use-keyboard-navigation-list";
import SellintuService from "../../../services/sellintu-service";


// @ts-ignore
const SearchModal = ({handleClose}) => {
    const [q, setQ] = React.useState("")
    const query = useDebounce(q, 500)
    const onChange = (e: { target: { value: React.SetStateAction<string> } }) => setQ(e.target.value)
    const handleClear = () => {
        setQ("")
    }

    const [orders, setOrders] = useState([""])
    const totalLength = orders.length
    const [isFetching, setIsFetching] = useState(true)

    // todo: remove this when API is ready
    const data = new SellintuService().makeData()
        .map((order => order.id))
        .filter(id => {
                return id.includes(query.toLowerCase())
            }
        )

    useEffect(() => {
        new SellintuService().ordersGet()
            // todo: to test
            .then((response) => {
                setOrders(response.data
                    // @ts-ignore
                    .map((order => order.id))
                    // @ts-ignore
                    .filter(id => {
                            return id.includes(query.toLowerCase())
                        }
                    ))
                setIsFetching(false)
            })
            .catch((error) => {
                // @ts-ignore
                setIsFetching(false)
            });
    }, [])

    const {getInputProps, getLIProps, getULProps, selected} =
        useKeyboardNavigationList({
            length: totalLength,
        })

    return (
        <RadixDialog.Root open onOpenChange={handleClose}>
            <RadixDialog.Portal>
                <RadixDialog.Overlay
                    className={clsx(
                        "fixed inset-0 z-50 pt-[140px] pb-[100px] backdrop-blur-sm",
                        {flex: totalLength > 0}
                    )}>
                    <RadixDialog.Content
                        className={clsx(
                            "bg-grey-0 rounded-rounded shadow-searchModal mx-auto flex max-w-[640px] flex-1"
                        )}>
                        <div className="py-large flex flex-1 flex-col">
                            <div
                                className="pb-large px-xlarge border-grey-20 flex items-center gap-x-4 border-b border-solid">
                                <SearchIcon className="text-grey-40"/>
                                <Input
                                    className="flex-1"
                                    onChange={onChange}
                                    value={q}
                                    placeholder="Start typing to search..."/>
                                <Tooltip
                                    className="bg-grey-0"
                                    onClick={handleClear}
                                    content="Clear search">
                                    <CrossIcon className="text-grey-50 flex"/>
                                </Tooltip>
                            </div>
                            <KeyboardShortcuts
                                className="mt-xlarge px-xlarge text-grey-40 inter-small-regular flex items-center gap-x-3"/>
                            {totalLength > 0 ? (
                                <ul
                                    className="mt-large px-xlarge flex-1 overflow-y-auto">
                                    {isFetching ? (
                                        <div className="pt-2xlarge flex w-full items-center justify-center">
                                            <Spinner size={"large"} variant={"secondary"}/>
                                        </div>
                                    ) : (
                                        <>
                                            <div>
                                                <OrderResults
                                                    orders={orders}
                                                    offset={0}
                                                    getLIProps={getLIProps}
                                                    selected={selected}/>
                                            </div>
                                        </>
                                    )}
                                </ul>
                            ) : null}
                        </div>
                    </RadixDialog.Content>
                </RadixDialog.Overlay>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    )
}

export default SearchModal
