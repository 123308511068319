import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import LoginCard from "../../components/organisms/login-card"
import ResetTokenCard from "../../components/organisms/register-card"
import SEO from "../../components/seo"
import PublicLayout from "../../components/templates/public-layout/login-layout"
import SellintuService from "../../services/sellintu-service";

const LoginPage = () => {
    const [resetPassword, setResetPassword] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        new SellintuService().pingAuth()
            .then(() => {
                navigate("/a/orders")
            })
            .catch((error) => {
            });
    }, []);

    useEffect(() => {
        if (window.location.search.includes("reset-password")) {
            setResetPassword(true)
        }
    }, [])

    const showLogin = () => {
        setResetPassword(false)
        navigate("/login", {replace: true})
    }

    const showResetPassword = () => {
        setResetPassword(true)
    }

    return (
        <PublicLayout>
            <SEO title=""/>
            {resetPassword ? (
                <ResetTokenCard goBack={showLogin}/>
            ) : (
                <LoginCard toResetPassword={showResetPassword}/>
            )}
        </PublicLayout>
    )
}

export default LoginPage
